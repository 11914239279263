import React from 'react'
import { Link, navigate } from 'gatsby'
import $ from 'jquery'
import { sessionKeys, errorMessageTemplates } from "../services/const"
import { getFormValues, setFormValues } from '../services/form'
import { getValue } from '../utils/check'
import Seo from '../components/seo'

const namepair = [{ "Lineages": "Lineage" }, { "CompanyClassifications": "CompanyClassification" }]
const displayLabels = {
  LastNameKanji: { Label: "お名前", Property: "NameKanji" },
  FirstNameKanji: { Label: "お名前", Property: "NameKanji" },
  LastNameKatakana: { Label: "お名前（フリガナ）", Property: "NameKatakana" },
  FirstNameKatakana: { Label: "お名前（フリガナ）", Property: "NameKatakana" },
  BirthYear: { Label: "生年月日", Property: "BirthDate" },
  BirthMonth: { Label: "生年月日", Property: "BirthDate" },
  BirthDay: { Label: "生年月日", Property: "BirthDate" },
  TelephoneNumber: { Label: "電話番号", Property: "TelephoneNumber"},
  PostalCode: { Label: "郵便番号" },
  Address: { Label: "自宅住所" },
  SendingAddress: { Label: "協会誌送付先" },
  PaymentType: { Label: "年会費支払い方法" },
  MailAddress: { Label: "メールアドレス" },
  MailAddressConfirmation: { Label: "メールアドレス" }
}

class JoinForm extends React.Component {
  state = {
    LastNameKanji: "",
    FirstNameKanji: "",
    LastNameKatakana: "",
    FirstNameKatakana: "",
    Gender: "",
    BirthYear: "",
    BirthMonth: "",
    BirthDay: "",
    PostalCode: "",
    Address: "",
    TelephoneNumber: "",
    SendingAddress: "",
    PaymentType: "",
    MailAddress: "",
    MailAddressConfirmation: "",
    CompanyName: "",
    BranchOffice: "",
    DeptName: "",
    JobTitle: "",
    CompanyPostalCode: "",
    CompanyAddress: "",
    TelephoneNumberNtt: "",
    TelephoneNumberJr: "",
    Lineages: [],
    CompanyClassifications: [],
    Message: "",
    ErrorLabelTexts: {}
  }

  getErrorLabelTexts = (state) => {
    const errorLabelTexts = {}
    const requires = ["LastNameKanji", "FirstNameKanji", "LastNameKatakana", "FirstNameKatakana",
      "BirthYear", "BirthMonth", "BirthDay", "PostalCode", "Address", "SendingAddress", "PaymentType",
      "MailAddress", "MailAddressConfirmation", "TelephoneNumber"]
    requires.forEach(v => {
      if (Array.isArray(state[v])) {
        if (state[v].length === 0) {
          errorLabelTexts[getValue(displayLabels[v].Property, v)] = displayLabels[v].Label + errorMessageTemplates.noInput
        }
      } else if (state[v] === null ||
        state[v] === "" ||
        state[v] === void 0 ||
        state[v].match(/^[ 　\r\n\t]*$/)) {
        errorLabelTexts[getValue(displayLabels[v].Property, v)] = displayLabels[v].Label + errorMessageTemplates.noInput

      } else if (v === "MailAddress" || v === "MailAddressConfirmation") {
        if (!state[v].match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/)) {
          errorLabelTexts[getValue(displayLabels[v].Property, v)] = displayLabels[v].Label + errorMessageTemplates.typo
        }
      } else if (v === "PostalCode") {
        if (!state[v].match(/^[0-9]{3}-[0-9]{4}$/)) {
          errorLabelTexts[getValue(displayLabels[v].Property, v)] = displayLabels[v].Label + errorMessageTemplates.typo
        }
      }
    })

    if (state["MailAddress"] !== state["MailAddressConfirmation"]) {
      errorLabelTexts["MailAddressInconsistency"] = displayLabels.MailAddressConfirmation.Label + errorMessageTemplates.inconsistency
    }

    return errorLabelTexts
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleOnBlur = event => {
  }

  handleInputChangeCheckBox = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const list = this.state[name]
    if (target.checked) {
      list.push(value)
    }
    else {
      const index = list.indexOf(value)
      list.splice(index, 1)
    }
    this.setState(
      {
        [name]: list,
      }
    )
  }

  componentDidMount = () => {
    $(document).ready(function () {
      $("#agree_policy").on("click", function () {
        if ($(this).prop("checked") === true) {
          $("#form_submit").prop("disabled", false);
        } else {
          $("#form_submit").prop("disabled", true);
        }
      });
    });

    const restoreValue = getFormValues(sessionKeys.joinForm, "ErrorLabelTexts")
    Object.keys(restoreValue).forEach(k => {
      this.setState({
        [k]: restoreValue[k],
      })
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    const errorMessages = this.getErrorLabelTexts(this.state)
    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        ErrorLabelTexts: errorMessages
      })
      navigate('#')
    } else {
      namepair.forEach((np, index) => {
        for (const [key, value] of Object.entries(np)) {
          const forMailValue = this.state[key].map(x => {
            return { [value]: x }
          })
          this.setState({
            [key + "ForMail"]: forMailValue,
          }, () => {
            setFormValues(sessionKeys.joinForm, this.state)
            if (namepair.length - 1 === index) {
              navigate('/joinFormConfirm')
            }
          })
        }
      })
    }
  }

  render = () => {
    return (
      <>
        <Seo title="入会申込み" />
        <div id="pageTitle">
          <h1>
            入会申込み
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li><Link to="/member">入会のご案内</Link></li>
            <li>入会申込み</li>
          </ul>
        </div>
        <div id="main">
          <div className="contents">

            <section className="contents_block">
              <p>当協会へ入会を希望される方は、下記のフォームよりお申し込みください。（*は入力必須項目です。）</p>
            </section>

            <form name="" onSubmit={this.handleSubmit} >
              <section className="contents_block narrow mb50">
                <h2 className="header02 mb20">基本情報</h2>

                <div className="form_block">
                  <div className="form_head">
                    <span className="form_require">お名前</span>
                  </div>
                  <div className="form_body">
                    <div className="form_body_parent">
                      <div className="form_body_child">
                        姓<input type="text" className="" name="LastNameKanji" value={this.state.LastNameKanji} onChange={this.handleInputChange} />
                      </div>
                      <div className="form_body_child">
                        名<input type="text" className="" name="FirstNameKanji" value={this.state.FirstNameKanji} onChange={this.handleInputChange} />
                      </div>
                    </div>
                    <div className="err"> {this.state.ErrorLabelTexts.NameKanji} </div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    <span className="form_require">お名前（カナ）</span>
                  </div>
                  <div className="form_body">
                    <div className="form_body_parent">
                      <div className="form_body_child">
                        姓（カナ）<input type="text" className="" name="LastNameKatakana" value={this.state.LastNameKatakana} onChange={this.handleInputChange} />
                      </div>
                      <div className="form_body_child">
                        名（カナ）<input type="text" className="" name="FirstNameKatakana" value={this.state.FirstNameKatakana} onChange={this.handleInputChange} />
                      </div>
                    </div>
                    <div className="err"> {this.state.ErrorLabelTexts.NameKatakana} </div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">性別</div>
                  <div className="form_body">
                    <div className="form_item">
                      <input type="radio" id="gender_M" className="" name="Gender" value="男性" onChange={this.handleInputChange} checked={this.state.Gender === "男性"} /><label htmlFor="gender_M">男性</label>
                    </div>
                    <div className="form_item">
                      <input type="radio" id="gender_F" className="" name="Gender" value="女性" onChange={this.handleInputChange} checked={this.state.Gender === "女性"} /><label htmlFor="gender_F">女性</label>
                    </div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    <span className="form_require">生年月日</span>
                  </div>
                  <div className="formField">
                    <div className="form_body three_selecter">
                      <label className="select-group1">
                        <select name="BirthYear" className="select" value={this.state.BirthYear} onChange={this.handleInputChange} onBlur={this.handleOnBlur}>
                          <option value="">選択</option>
                          <option value="1900">1900</option>
                          <option value="1901">1901</option>
                          <option value="1902">1902</option>
                          <option value="1903">1903</option>
                          <option value="1904">1904</option>
                          <option value="1905">1905</option>
                          <option value="1906">1906</option>
                          <option value="1907">1907</option>
                          <option value="1908">1908</option>
                          <option value="1909">1909</option>
                          <option value="1910">1910</option>
                          <option value="1911">1911</option>
                          <option value="1912">1912</option>
                          <option value="1913">1913</option>
                          <option value="1914">1914</option>
                          <option value="1915">1915</option>
                          <option value="1916">1916</option>
                          <option value="1917">1917</option>
                          <option value="1918">1918</option>
                          <option value="1919">1919</option>
                          <option value="1920">1920</option>
                          <option value="1921">1921</option>
                          <option value="1922">1922</option>
                          <option value="1923">1923</option>
                          <option value="1924">1924</option>
                          <option value="1925">1925</option>
                          <option value="1926">1926</option>
                          <option value="1927">1927</option>
                          <option value="1928">1928</option>
                          <option value="1929">1929</option>
                          <option value="1930">1930</option>
                          <option value="1931">1931</option>
                          <option value="1932">1932</option>
                          <option value="1933">1933</option>
                          <option value="1934">1934</option>
                          <option value="1935">1935</option>
                          <option value="1936">1936</option>
                          <option value="1937">1937</option>
                          <option value="1938">1938</option>
                          <option value="1939">1939</option>
                          <option value="1940">1940</option>
                          <option value="1941">1941</option>
                          <option value="1942">1942</option>
                          <option value="1943">1943</option>
                          <option value="1944">1944</option>
                          <option value="1945">1945</option>
                          <option value="1946">1946</option>
                          <option value="1947">1947</option>
                          <option value="1948">1948</option>
                          <option value="1949">1949</option>
                          <option value="1950">1950</option>
                          <option value="1951">1951</option>
                          <option value="1952">1952</option>
                          <option value="1953">1953</option>
                          <option value="1954">1954</option>
                          <option value="1955">1955</option>
                          <option value="1956">1956</option>
                          <option value="1957">1957</option>
                          <option value="1958">1958</option>
                          <option value="1959">1959</option>
                          <option value="1960">1960</option>
                          <option value="1961">1961</option>
                          <option value="1962">1962</option>
                          <option value="1963">1963</option>
                          <option value="1964">1964</option>
                          <option value="1965">1965</option>
                          <option value="1966">1966</option>
                          <option value="1967">1967</option>
                          <option value="1968">1968</option>
                          <option value="1969">1969</option>
                          <option value="1970">1970</option>
                          <option value="1971">1971</option>
                          <option value="1972">1972</option>
                          <option value="1973">1973</option>
                          <option value="1974">1974</option>
                          <option value="1975">1975</option>
                          <option value="1976">1976</option>
                          <option value="1977">1977</option>
                          <option value="1978">1978</option>
                          <option value="1979">1979</option>
                          <option value="1980">1980</option>
                          <option value="1981">1981</option>
                          <option value="1982">1982</option>
                          <option value="1983">1983</option>
                          <option value="1984">1984</option>
                          <option value="1985">1985</option>
                          <option value="1986">1986</option>
                          <option value="1987">1987</option>
                          <option value="1988">1988</option>
                          <option value="1989">1989</option>
                          <option value="1990">1990</option>
                          <option value="1991">1991</option>
                          <option value="1992">1992</option>
                          <option value="1993">1993</option>
                          <option value="1994">1994</option>
                          <option value="1995">1995</option>
                          <option value="1996">1996</option>
                          <option value="1997">1997</option>
                          <option value="1998">1998</option>
                          <option value="1999">1999</option>
                          <option value="2000">2000</option>
                          <option value="2001">2001</option>
                          <option value="2002">2002</option>
                          <option value="2003">2003</option>
                          <option value="2004">2004</option>
                          <option value="2005">2005</option>
                          <option value="2006">2006</option>
                          <option value="2007">2007</option>
                          <option value="2008">2008</option>
                          <option value="2009">2009</option>
                          <option value="2010">2010</option>
                          <option value="2011">2011</option>
                          <option value="2012">2012</option>
                          <option value="2013">2013</option>
                          <option value="2014">2014</option>
                          <option value="2015">2015</option>
                          <option value="2016">2016</option>
                          <option value="2017">2017</option>
                          <option value="2018">2018</option>
                          <option value="2019">2019</option>
                          <option value="2020">2020</option>
                          <option value="2021">2021</option></select>
                      </label>
                      年&nbsp;
                      <label className="select-group1">
                        <select name="BirthMonth" className="select" value={this.state.BirthMonth} onChange={this.handleInputChange} onBlur={this.handleOnBlur}>
                          <option value="">選択</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </label>
                      月&nbsp;
                      <label className="select-group1">
                        <select name="BirthDay" className="select" value={this.state.BirthDay} onChange={this.handleInputChange} onBlur={this.handleOnBlur}>
                          <option value="">選択</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                        </select>
                      </label>
                      日</div>
                    <div className="err"> {this.state.ErrorLabelTexts.BirthDate} </div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    <span className="form_require">郵便番号</span>
                  </div>
                  <div className="form_body">
                    <div className="form_body_child">
                      <input type="text" className="form_size_200" placeholder="例）100-1000" name="PostalCode" value={this.state.PostalCode} onChange={this.handleInputChange} />
                    </div>
                    <div className="err"> {this.state.ErrorLabelTexts.PostalCode} </div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    <span className="form_require">自宅住所</span>
                  </div>
                  <div className="form_body">
                    <input type="text" className="" name="Address" value={this.state.Address} onChange={this.handleInputChange} />
                    <div className="err"> {this.state.ErrorLabelTexts.Address} </div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    <span className="form_require">電話番号</span>
                  </div>
                  <div className="form_body">
                    <input type="text" className="" placeholder="例）03-1234-5678" name="TelephoneNumber" value={this.state.TelephoneNumber} onChange={this.handleInputChange} />
                    <div className="err"> {this.state.ErrorLabelTexts.TelephoneNumber} </div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    <span className="form_require">協会誌等送付先</span>
                  </div>
                  <div className="form_body">
                    <input type="radio" id="sendingAddress_workplace" className="" name="SendingAddress" value="勤務先" onChange={this.handleInputChange} checked={this.state.SendingAddress === "勤務先"} /><label htmlFor="sendingAddress_workplace">勤務先</label>
                    <input type="radio" id="sendingAddress_home" className="" name="SendingAddress" value="自宅" onChange={this.handleInputChange} checked={this.state.SendingAddress === "自宅"} /><label htmlFor="sendingAddress_home">自宅</label>
                    <div className="err"> {this.state.ErrorLabelTexts.SendingAddress} </div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    <span className="form_require">年会費支払方法</span>
                  </div>
                  <div className="form_body">
                    <input type="radio" id="paymentType_account" className="" name="PaymentType" value="口座振替" onChange={this.handleInputChange} checked={this.state.PaymentType === "口座振替"} /><label htmlFor="paymentType_account">口座振替</label>
                    <input type="radio" id="paymentType_remittance" className="" name="PaymentType" value="振込" onChange={this.handleInputChange} checked={this.state.PaymentType === "振込"} /><label htmlFor="paymentType_remittance">振込</label>
                    <div className="err"> {this.state.ErrorLabelTexts.PaymentType} </div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    <span className="form_require">メールアドレス</span>
                  </div>
                  <div className="form_body">
                    <input type="text" className="" name="MailAddress" value={this.state.MailAddress} onChange={this.handleInputChange} />
                    <div className="err"> {this.state.ErrorLabelTexts.MailAddress} </div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    <span className="form_require">メールアドレス（確認）</span>
                  </div>
                  <div className="form_body">
                    <input type="text" className="" name="MailAddressConfirmation" value={this.state.MailAddressConfirmation} onChange={this.handleInputChange} />
                    <div className="err"> {this.state.ErrorLabelTexts.MailAddressConfirmation} </div>
                    <div className="err"> {this.state.ErrorLabelTexts.MailAddressInconsistency} </div>
                  </div>
                </div>
              </section>

              <section className="contents_block narrow mb50">
                <h2 className="header02 mb20">勤務先情報</h2>
                <div className="form_block">
                  <div className="form_head">会社名</div>
                  <div className="form_body">
                    <input type="text" className="" name="CompanyName" value={this.state.CompanyName} onChange={this.handleInputChange} />
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">支社・支店・営業所名</div>
                  <div className="form_body">
                    <input type="text" className="" name="BranchOffice" value={this.state.BranchOffice} onChange={this.handleInputChange} />
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">部署・課名</div>
                  <div className="form_body">
                    <input type="text" className="" name="DeptName" value={this.state.DeptName} onChange={this.handleInputChange} />
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">役職名</div>
                  <div className="form_body">
                    <input type="text" className="" name="JobTitle" value={this.state.JobTitle} onChange={this.handleInputChange} />
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">郵便番号</div>
                  <div className="form_body">
                    <div className="form_body_child">
                      <input type="text" className="form_size_200" placeholder="例）100-1000" name="CompanyPostalCode" value={this.state.CompanyPostalCode} onChange={this.handleInputChange} />
                    </div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">所在地</div>
                  <div className="form_body">
                    <input type="text" className="" name="CompanyAddress" value={this.state.CompanyAddress} onChange={this.handleInputChange} />
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">電話番号（NTT）</div>
                  <div className="form_body">
                    <input type="text" className="" placeholder="例）03-1234-5678" name="TelephoneNumberNtt" value={this.state.TelephoneNumberNtt} onChange={this.handleInputChange} />
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">電話番号（JR）</div>
                  <div className="form_body">
                    <input type="text" className="" placeholder="例）012-3456" name="TelephoneNumberJr" value={this.state.TelephoneNumberJr} onChange={this.handleInputChange} />
                  </div>
                </div>
              </section>

              <section className="contents_block narrow">
                <h2 className="header02 mb20">その他の情報</h2>
                <div className="form_block">
                  <div className="form_head">系統</div>
                  <div className="form_body">
                    <div className="form_item"><label><input type="checkbox" className="" name="Lineages" value="線路" onChange={this.handleInputChangeCheckBox} checked={this.state.Lineages.some(v => v === "線路")} />線路</label></div>
                    <div className="form_item"><label><input type="checkbox" className="" name="Lineages" value="土木" onChange={this.handleInputChangeCheckBox} checked={this.state.Lineages.some(v => v === "土木")} />土木</label></div>
                    <div className="form_item"><label><input type="checkbox" className="" name="Lineages" value="事務" onChange={this.handleInputChangeCheckBox} checked={this.state.Lineages.some(v => v === "事務")} />事務</label></div>
                    <div className="form_item"><label><input type="checkbox" className="" name="Lineages" value="その他" onChange={this.handleInputChangeCheckBox} checked={this.state.Lineages.some(v => v === "その他")} />その他</label></div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">企業区分</div>
                  <div className="form_body">
                    <div className="form_item"><label><input type="checkbox" className="" name="CompanyClassifications" value="JR" onChange={this.handleInputChangeCheckBox} checked={this.state.CompanyClassifications.some(v => v === "JR")} />JR</label></div>
                    <div className="form_item"><label><input type="checkbox" className="" name="CompanyClassifications" value="鉄道・運輸機構（国鉄事業本部）" onChange={this.handleInputChangeCheckBox} checked={this.state.CompanyClassifications.some(v => v === "鉄道・運輸機構（国鉄事業本部）")} />鉄道・運輸機構（国鉄事業本部）</label></div>
                    <div className="form_item"><label><input type="checkbox" className="" name="CompanyClassifications" value="鉄道総研" onChange={this.handleInputChangeCheckBox} checked={this.state.CompanyClassifications.some(v => v === "鉄道総研")} />鉄道総研</label></div>
                    <div className="form_item"><label><input type="checkbox" className="" name="CompanyClassifications" value="建設業" onChange={this.handleInputChangeCheckBox} checked={this.state.CompanyClassifications.some(v => v === "建設業")} />建設業</label></div>
                    <div className="form_item"><label><input type="checkbox" className="" name="CompanyClassifications" value="その他" onChange={this.handleInputChangeCheckBox} checked={this.state.CompanyClassifications.some(v => v === "その他")} />その他</label></div>
                    <div className="form_item"><label><input type="checkbox" className="" name="CompanyClassifications" value="鉄道･運輸機構" onChange={this.handleInputChangeCheckBox} checked={this.state.CompanyClassifications.some(v => v === "鉄道･運輸機構")} />鉄道･運輸機構</label></div>
                    <div className="form_item"><label><input type="checkbox" className="" name="CompanyClassifications" value="国土交通省" onChange={this.handleInputChangeCheckBox} checked={this.state.CompanyClassifications.some(v => v === "国土交通省")} />国土交通省</label></div>
                    <div className="form_item"><label><input type="checkbox" className="" name="CompanyClassifications" value="公民鉄" onChange={this.handleInputChangeCheckBox} checked={this.state.CompanyClassifications.some(v => v === "公民鉄")} />公民鉄</label></div>
                    <div className="form_item"><label><input type="checkbox" className="" name="CompanyClassifications" value="コンサルタント" onChange={this.handleInputChangeCheckBox} checked={this.state.CompanyClassifications.some(v => v === "コンサルタント")} />コンサルタント</label></div></div>
                </div>

                <div className="form_block">
                  <div className="form_head">通信欄</div>
                  <div className="form_body">
                    <textarea className="textarea_contact" name="Message" value={this.state.Message} onChange={this.handleInputChange} ></textarea>
                  </div>
                </div>
              </section>

              <section className="contents_block narrow">
                <p>プライバシーポリシーに同意いただける場合は「同意する」にチェックを付け、以下の入力に進んでください。<br />
                  ※チェックがない（同意していただけない）場合は入力されても送信出来ません。</p>
                <div className="join_form_privacy">
                  <p>
                    ＜個人情報保護方針＞<br />
                    <br />
                    プライバシーポリシーに関して<br />
                    <br />
                    一般社団法人<br />
                    日本鉄道施設協会は、個人情報保護の社会的重要性を強く認識し、その適切な管理を行うことが重要な社会的責務と考え、ご利用の方が安心してご利用いただけるよう運営・管理に努めます。<br />
                    <br />
                    ■個人情報とは<br />
                    当協会が当サイトをご利用される方から提供を受ける住所、氏名、年齢、電話番号、メールアドレス等、個人を識別できる情報または個人固有の情報をいいます。<br />
                    <br />
                    ■個人情報の収集・取り扱いについて<br />
                    当協会が運営するWEBサイト内の一部のページに個人情報のご提供をお願いする場合があります。 収集した個人情報の取り扱いについては、個人情報の重要性を認識し、厳しい管理基準を設け管理を行っています。 収集された個人情報は、提供を受ける際にご了承頂いた利用範囲内での利用を目的とし、第三者への開示は原則として致しません。<br />
                    <br />
                    ■このプライバシーの考え方の改訂について<br />
                    当協会は、当サイトにおけるプライバシーの考え方を改訂することがあります。 重要な変更がある場合には、当サイト上において、分かりやすい方法でお知らせします。<br />
                    <br />
                    ■管理責任者及びお問合せ窓口について<br />
                    当協会では、当サイトにおけるお問合せ窓口を設置しております。 ご質問やご意見がございましたら、各事業所の電話等からお問い合わせください。<br />
                    <br />
                    一般社団法人 日本鉄道施設協会
                  </p>
                </div>
                <div className="aC mb30">
                  <div className="form_item"><label><input id="agree_policy" type="checkbox" className="" />同意する</label></div>
                </div>
              </section>
              <div className="aC mb50"><input id="form_submit" type="submit" value="入力内容を確認する" className="btn_rounded" disabled /></div>
            </form>
          </div>
        </div>
      </>
    )

  }
}


export default JoinForm